
import {
  defineComponent,
  ref,
  createVNode,
  reactive,
  toRefs,
  nextTick,
  onMounted,
  watch,
} from "vue";
import { useRouter } from "vue-router";
import {
  getQueryList,
  judgeCallBack,
  getModalYear,
} from "@/API/claim/claimQuery";
import { getBuSubregion } from "@/API/salesReporting/claimCheckingReport";
import { message } from "ant-design-vue";
import { Modal } from "ant-design-vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import downloadFile from "@/utils/claim/downloadFile";
import recallUpload from "./recallUpload/index.vue";
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";
import {
  getFindByGroupCode,
  getCheckingState,
} from "@/API/checking/SICCOCheckingDetail";
import useFormatDate from "@/utils/payment/useFormatDate";
const tableWidth = window.innerWidth;
interface QueryListType {
  vinNo: string | null;
  vinNoList: string[];
  dealerCode: string | null;
  dealerSwtCode: string | null;
  model: string | null;
  eligiblePeriod: string[];
  dealerNameCn: string | null;
  inParagraph: string | undefined;
  complaintStatus: string | null;
  createdAt: string[];
  programCode: string | null;
  retailAT: string[];
  bu: string | null;
  region: string[];
  checkResult: string[];
  status: string[];
  automaticDeduction: string | null;
}
interface SearchListType {
  vinNo: string | null;
  vinNoList: string[];
  dealerCode: string | null;
  dealerSwtCode: string | null;
  model: string | null;
  eligiblePeriodQueryFrom: string | null;
  eligiblePeriodQueryTo: string | null;
  dealerNameCn: string | null;
  inParagraph: string | undefined;
  complaintStatus: string | null;
  createdAtQueryFrom: string | null;
  createdAtQueryTo: string | null;
  current: number | 0;
  size: number | 20;
  claimType: number | 1;
  programCode: string | null;
  retailBegin: string | null;
  retailEnd: string | null;
  bu: string;
  region: string[];
  checkResult: string[];
  status: string[];
  statusCn: string[];
  autoDeduction: string | null;
  exemptionStatus?: boolean;
}
interface TableType {
  id: string;
  dealerCode: string;
  dealerSwtCode: string;
  dealerNameCn: string;
  programCode: string;
  eligiblePeriodStr: string;
  offerTypeName: string;
  optionName: string;
  vinNo: string;
  model: string;
  inParagraph: string;
  payRound: string;
  checkResult: string;
  complaintStatus: string;
  predictAmount: string;
  price: string;
  createdTime: string;
  status: string;
  withdrawFlag: string;
  bu: string;
}

enum Status {
  Exemption = '99'
}

export default defineComponent({
  components: { recallUpload },
  setup() {
    const { t, locale } = useI18n();
    const placeholder = ref("Please Select...");
    const isDealer = ref(true);

    const columns = ref<any[]>([
      {
        title: t("claimEnquiry.projectNO"),
        dataIndex: "programCode",
        width: 150,
      },
      {
        title: t("claimEnquiry.dealerSwtCode"),
        dataIndex: "dealerSwtCode",
        width: 120,
      },
      {
        title: t("claimEnquiry.projectPeriod"),
        dataIndex: "eligiblePeriodStr",
        width: 180,
      },
      {
        title: t("claimEnquiry.projectType"),
        dataIndex: "offerTypeName",
        width: 220,
      },
      {
        title: t("claimEnquiry.optionName"),
        dataIndex: "optionName",
        width: 130,
      },
      { title: t("claimEnquiry.vinNO"), dataIndex: "vinNo", width: 170 },
      {
        title: t("claimEnquiry.retailDateStr"),
        dataIndex: "retailDateStr",
        width: 170,
      },
      {
        title: t("claimEnquiry.vehicleName"),
        dataIndex: "model",
        width: 300,
      },
      {
        title: t("claimEnquiry.modelYear"),
        dataIndex: "inParagraph",
        width: 130,
      },
      {
        title: t("claimEnquiry.paymentRound"),
        dataIndex: "payRound",
        width: 230,
      },
      {
        title: t("claimEnquiry.checkResults"),
        dataIndex: "checkResult",
        width: 100,
      },
      {
        title: t("claimEnquiry.appealStatus"),
        dataIndex: "isAppeal",
        width: 100,
      },
      {
        title: t("claimEnquiry.estimatedAmountOfCustomerPolicy"),
        dataIndex: "predictAmount",
        width: 150,
      },
      {
        title: t("claimEnquiry.actualAmount"),
        dataIndex: "finalBonus",
        width: 130,
      },
      {
        title: t("claimEnquiry.createTime"),
        dataIndex: "createdTime",
        width: 120,
      },
      {
        title: t("claimEnquiry.status"),
        dataIndex: isDealer.value ? "statusCn" : "status",
        width: 100,
      },
      {
        title: t("claimEnquiry.automaticDeduction"),
        dataIndex: "autoDeduction",
        width: 100,
      },
      {
        title: t("claimEnquiry.dealerTradeWholeSaleDate"),
        dataIndex: "dealerTradeWholeSaleDate",
        width: 100,
      },
      {
        title: t("claimEnquiry.dealerTrade"),
        dataIndex: "dealerTrade",
        width: 100,
      },
      {
        title: t("claimEnquiry.dealerTradeWholeSaleCode"),
        dataIndex: "dealerTradeWholeSaleCode",
        width: 160,
      },
      {
        title: t("claimEnquiry.dealerTradeWholeSaleName"),
        dataIndex: "dealerTradeWholeSaleNameCn",
        width: 260,
      },
      {
        title: t("claimEnquiry.operation"),
        fixed: "right",
        width: 120,
        slots: { customRender: "operation" },
      },
    ]);

    const store = useStore();

    //撤回的code
    const callBackCode = ref("");
    //无法撤回的Modal
    const callBackVisible = ref(false);

    //子组件
    const recallUploadDom = ref<any>();

    const searchCon = ref();
    const tableHeight = ref();
    // 检查状态list值
    const checkResultList = ref<any[]>([]);
    // 年款假值
    const inParagraphList = ref<any[]>([]);
    // 申诉状态
    const complaintStatusList = reactive([
      { id: "Y", name: "Y" },
      { id: "N", name: "N" },
    ]);
    //筛选条件，各个信息框的内容
    const queryListParams = reactive<QueryListType>({
      vinNo: "",
      vinNoList: [],
      dealerCode: "",
      dealerSwtCode: "",
      model: "",
      eligiblePeriod: [],
      dealerNameCn: "",
      inParagraph: undefined,
      complaintStatus: null,
      createdAt: [],
      programCode: "",
      retailAT: [],
      bu: null,
      region: [],
      checkResult: [],
      status: [],
      automaticDeduction: null,
    });
    // 设置搜索值
    const params = reactive<SearchListType>({
      dealerCode: "",
      dealerSwtCode: "",
      model: "",
      eligiblePeriodQueryFrom: "",
      eligiblePeriodQueryTo: "",
      dealerNameCn: "",
      inParagraph: undefined,
      complaintStatus: "",
      createdAtQueryFrom: "",
      createdAtQueryTo: "",
      current: 0,
      size: 20,
      claimType: 1,
      vinNoList: [],
      vinNo: "",
      programCode: "",
      retailBegin: "",
      retailEnd: "",
      bu: "",
      region: [],
      checkResult: [],
      status: [],
      statusCn: [],
      autoDeduction: "",
      exemptionStatus: undefined,
    });
    //显示的表格数据
    const queryListData = ref<TableType[]>([]);

    const businessUnitSelect = store.state.user.buList.filter((bu: any) => {
      return bu.parentId === null;
    });
    const canSelectRegion = ref(false);
    watch(
      () => queryListParams.bu,
      (newVal) => {
        if (newVal) {
          canSelectRegion.value = true;
        } else {
          canSelectRegion.value = false;
        }
      }
    );
    const regionLst = ref<any>([]);
    const dogetRegion = () => {
      regionLst.value = [];
      getBuSubregion({ buId: queryListParams.bu }).then((res) => {
        queryListParams.region = [];
        const sortArr: any[] = [[], [], [], [], []];
        const maps = {
          n: 0,
          e: 1,
          s: 3,
          w: 2,
        };
        res.forEach((region: any) => {
          const firstLetter = (
            region.regionEn[0] as string
          ).toLocaleLowerCase();
          if (firstLetter in maps) {
            sortArr[maps[firstLetter]].push(region);
          } else {
            sortArr[4].push(region);
          }
        });
        regionLst.value = sortArr.flat();
      });
    };
    //分页组件
    const pagination = reactive({
      pageSize: 20,
      currentPage: 0,
      total: 0,
    });
    // 获取params
    const getParams = () => {
      params.dealerCode = "";
      params.dealerSwtCode = "";
      params.model = "";
      params.eligiblePeriodQueryFrom = "";
      params.eligiblePeriodQueryTo = "";
      params.dealerNameCn = "";
      params.inParagraph = undefined;
      params.complaintStatus = "";
      params.createdAtQueryFrom = "";
      params.createdAtQueryTo = "";
      params.current = pagination.currentPage;
      params.size = pagination.pageSize;
      params.claimType = 1;
      params.vinNoList =
        queryListParams.vinNoList.length === 1 &&
        queryListParams.vinNoList[0] === ""
          ? []
          : queryListParams.vinNoList;
      params.vinNo =
        queryListParams.vinNoList.length >= 1 &&
        queryListParams.vinNoList[0] !== ""
          ? ""
          : queryListParams.vinNo;
      params.programCode = "";
      params.retailBegin = "";
      params.retailEnd = "";

      params.autoDeduction = queryListParams.automaticDeduction || "";
      params.bu = queryListParams.bu || "";
      params.region = queryListParams.region;
      params.checkResult = queryListParams.checkResult;
      if (isDealer.value) {
        params.statusCn = queryListParams.status;
      } else {
        params.status = queryListParams.status;
      }

      if (queryListParams.status.includes(Status.Exemption)) {
        params.exemptionStatus = true
      } else {
        params.exemptionStatus = undefined
      }

      if (queryListParams.retailAT.length > 0) {
        params.retailBegin = queryListParams.retailAT[0];
        params.retailEnd = queryListParams.retailAT[1];
      }

      if (
        queryListParams.programCode !== null &&
        queryListParams.programCode !== ""
      ) {
        params.programCode = queryListParams.programCode;
      }

      if (
        queryListParams.dealerCode !== null &&
        queryListParams.dealerCode !== ""
      ) {
        params.dealerCode = queryListParams.dealerCode;
      }

      if (
        queryListParams.dealerSwtCode !== null &&
        queryListParams.dealerSwtCode !== ""
      ) {
        params.dealerSwtCode = queryListParams.dealerSwtCode;
      }

      if (isDealer.value) {
        params.claimType = 1;
        params.dealerCode = (store.state.user as any).organization.entityCode;

        placeholder.value = "请选择...";
      } else {
        params.claimType = 2;
        placeholder.value = "Please select...";
      }

      if (queryListParams.model !== null && queryListParams.model !== "") {
        params.model = queryListParams.model;
      }

      if (queryListParams.eligiblePeriod.length > 0) {
        params.eligiblePeriodQueryFrom = queryListParams.eligiblePeriod[0];
        params.eligiblePeriodQueryTo = queryListParams.eligiblePeriod[1];
      }

      if (
        queryListParams.dealerNameCn !== null &&
        queryListParams.dealerNameCn !== ""
      ) {
        params.dealerNameCn = queryListParams.dealerNameCn;
      }

      if (
        queryListParams.inParagraph !== undefined &&
        queryListParams.inParagraph !== ""
      ) {
        params.inParagraph = queryListParams.inParagraph;
      }

      if (
        queryListParams.complaintStatus !== null &&
        queryListParams.complaintStatus !== ""
      ) {
        params.complaintStatus = queryListParams.complaintStatus;
      }

      if (queryListParams.createdAt.length > 0) {
        params.createdAtQueryFrom = queryListParams.createdAt[0];
        params.createdAtQueryTo = queryListParams.createdAt[1];
      }
    };

    //根据筛选框信息，查询表格
    const getTableData = () => {
      getParams();
      getQueryList(params).then((res: any): void => {
        queryListData.value = res.claimApplicationVOS;

        for (let i = 0; i < queryListData.value.length; i++) {
          if (
            (queryListData.value[i] as any).incentiveCategory !==
            "Customer Incentive"
          ) {
            queryListData.value[i].predictAmount = "";
          }
        }

        pagination.total = parseInt(res.totalElements);
      });
    };

    const queryTable = () => {
      pagination.currentPage = 0;
      getTableData();
    };
    getTableData();
    const clearQueryListParams = () => {
      queryListParams.vinNo = "";
      queryListParams.vinNoList = [""];
      queryListParams.dealerCode = "";
      queryListParams.programCode = "";
      queryListParams.dealerSwtCode = "";
      queryListParams.model = "";
      queryListParams.eligiblePeriod = [];
      queryListParams.retailAT = [];
      queryListParams.dealerNameCn = "";
      queryListParams.inParagraph = undefined;
      queryListParams.complaintStatus = null;
      queryListParams.createdAt = [];
      queryListParams.bu = null;
      queryListParams.region = [];
      queryListParams.checkResult = [];
      queryListParams.status = [];
      queryListParams.automaticDeduction = "";
      pagination.pageSize = 20;
      pagination.currentPage = 0;
      pagination.total = 0;
    };
    //更改分页组件，包括：直接跳转到指定页，上一页下一页
    const changePagination = (page: number, pagesize: number) => {
      pagination.pageSize = pagesize;
      pagination.currentPage = page - 1 > 0 ? page - 1 : 0;
      getTableData();
    };
    //更改每页size
    const changePageSize = (page: number, pagesize: number) => {
      pagination.pageSize = pagesize;
      pagination.currentPage = page - 1 > 0 ? page - 1 : 0;
      getTableData();
    };
    //撤回操作
    const recallHandle = (record: any) => {
      console.log(record);
      if (record.status === "检查中") {
        callBackCode.value = record.programCode;
        // <p>当前项目{{callBackCode}}状态为<span style="color:#e1292b">核查中</span>，无法撤回操作。</p>
        Modal.warning({
          title: "Tips",
          okText: t("claimEnquiry.ok"),
          content:
            locale.value === "en"
              ? `The current project ${callBackCode.value} status is 核查中, cannot withdraw.`
              : `当前项目${callBackCode.value}状态为核查中，无法撤回操作。`,
        });
        // callBackVisible.value= true;
        return;
      }

      judgeCallBack({ claimAppId: record.id }).then((res: any): void => {
        console.log(res);
        if (res.code === "0") {
          message.success(t("claimEnquiry.withdrawSuccessful"));
          getTableData();
          return;
        }
        if (res.code === "50216" || res.code === "50266") {
          // message.error(res.data);
          Modal.warning({
            title: "Tips",
            okText: t("claimEnquiry.ok"),
            content: res.message,
          });
          return;
        }
        if (res.code === "50217") {
          Modal.confirm({
            title: "Tips",
            icon: createVNode(ExclamationCircleOutlined),
            content:
              locale.value === "en"
                ? "The withdrawal time of the current declared items has passed"
                : "当前申报项目已过撤回时间",
            okText: t("claimEnquiry.close"),
            cancelText: t("claimEnquiry.applyDeferredWithdraw"),
            onCancel: () => {
              recallUploadDom.value.showDrawer(record);
            },
          });
        }
        //
      });
    };
    // 删除数据接口调用
    // const deleteHandle = (record: any) => {
    //   const fromDate = new Date(record.submissionDateFrom).getTime();
    //   const toDate = new Date(record.submissionDateTo).getTime();
    //   const now = new Date().getTime();
    //   if (now >= fromDate && now <= toDate) {
    //     const id = record.id;

    //     Modal.confirm({
    //       title: '确认',
    //       content: '确定要删除这条支持文档吗？',
    //       okText: '确认',
    //       onOk() {
    //         deleteInfo(id).then((res) => {
    //           if (res.code === "0") {
    //             message.success(res.message);
    //             getTableData();
    //           } else {
    //             Modal.error({
    //               title: "Tips",
    //               content: res.message,
    //               centered: true,
    //             });
    //           }
    //         });
    //       },
    //       cancelText: '取消',
    //     });

    //   } else {
    //     Modal.error({
    //       title: "Tips",
    //       content: '项目已经过截止提交日期，不能删除！',
    //       centered: true,
    //     });
    //   }
    // };

    const exportResult = (type: string) => {
      getParams();
      const paramsConfig = {
        url: `/claimapi/salesIncentiveClaim/${type}`,
        method: "post",
        params: params,
        fileName: `Sales_Incentive_Claim_Export_Template.xlsx`,
      };
      downloadFile(paramsConfig, "application/vnd-excel; char-set=UTF-8");
    };
    nextTick(() => {
      (document.getElementsByClassName("table-box")[0] as any).style.height =
        window.innerHeight - 220 - searchCon.value.$el.scrollHeight + "px";
      const height = (document.getElementsByClassName("table-box")[0] as any)
        .style.height;
      const heightNum = parseInt(height.substr(0, height.indexOf("px")));
      tableHeight.value = heightNum - 110;
    });
    //获取年款下拉框信息
    const queryModalYear = () => {
      getModalYear().then((res: any): void => {
        // console.log(res)
        const array = [];
        for (let i = 0; i < res.length; i++) {
          if (res[i] === null || res[i] === "") {
            continue;
          }
          array.push({
            id: res[i],
            name: res[i],
          });
        }
        inParagraphList.value = array;
      });
    };

    onMounted(() => {
      if ((store.state.user as any).legalEntity.entityCode !== "Dealer") {
        isDealer.value = false;
        placeholder.value = "Please select...";
        columns.value = [
          {
            title: t("claimEnquiry.dealerCode"),
            dataIndex: "dealerCode",
            width: 120,
          },
          {
            title: t("claimEnquiry.dealerSwtCode"),
            dataIndex: "dealerSwtCode",
            width: 120,
          },
          {
            title: t("claimEnquiry.dealerNameCN"),
            dataIndex: "dealerNameCn",
            width: 310,
          },
          {
            title: t("claimEnquiry.projectNO"),
            dataIndex: "programCode",
            width: 150,
          },
          {
            title: t("claimEnquiry.projectPeriod"),
            dataIndex: "eligiblePeriodStr",
            width: 180,
          },
          {
            title: t("claimEnquiry.projectType"),
            dataIndex: "offerTypeName",
            width: 220,
          },
          {
            title: t("claimEnquiry.optionName"),
            dataIndex: "optionName",
            width: 130,
          },
          {
            title: t("claimEnquiry.vinNO"),
            dataIndex: "vinNo",
            width: 170,
          },
          {
            title: t("claimEnquiry.retailDateStr"),
            dataIndex: "retailDateStr",
            width: 170,
          },
          {
            title: t("claimEnquiry.vehicleName"),
            dataIndex: "model",
            width: 300,
          },
          {
            title: t("claimEnquiry.modelYear"),
            dataIndex: "inParagraph",
            width: 130,
          },
          {
            title: t("claimEnquiry.paymentRound"),
            dataIndex: "payRound",
            width: 230,
          },
          {
            title: t("claimEnquiry.checkResults"),
            dataIndex: "checkResult",
            width: 100,
          },
          {
            title: t("claimEnquiry.appealStatus"),
            dataIndex: "isAppeal",
            width: 100,
          },
          {
            title: t("claimEnquiry.estimatedAmountOfCustomerPolicy"),
            dataIndex: "predictAmount",
            width: 150,
          },
          {
            title: t("claimEnquiry.actualAmount"),
            dataIndex: "finalBonus",
            width: 130,
          },
          {
            title: t("claimEnquiry.createTime"),
            dataIndex: "createdTime",
            width: 120,
          },
          {
            title: t("claimEnquiry.status"),
            dataIndex: "status",
            width: 100,
          },
          {
            title: t("claimEnquiry.dealerCommunicationDeadline"),
            dataIndex: "confirmDeadline",
            customRender: useFormatDate("MM/DD/YYYY"),
            width: 150,
          },
          {
            title: t("claimEnquiry.exemptionDeadline"),
            dataIndex: "exemptionDeadline",
            customRender: useFormatDate("MM/DD/YYYY"),
            width: 150,
          },
          {
            title: t("claimEnquiry.dealerAcceptedTime"),
            dataIndex: "acceptedTime",
            customRender: useFormatDate("MM/DD/YYYY"),
            width: 150,
          },
          {
            title: t("claimEnquiry.automaticDeduction"),
            dataIndex: "autoDeduction",
            width: 100,
          },
          {
            title: t("claimEnquiry.dealerTrade"),
            dataIndex: "dealerTrade",
            width: 100,
          },
          {
            title: t("claimEnquiry.dealerTradeWholeSaleCode"),
            dataIndex: "dealerTradeWholeSaleCode",
            width: 160,
          },
          {
            title: t("claimEnquiry.dealerTradeWholeSaleName"),
            dataIndex: "dealerTradeWholeSaleNameCn",
            width: 260,
          },
          {
            title: t("claimEnquiry.dealerTradeWholeSaleDate"),
            dataIndex: "dealerTradeWholeSaleDate",
            width: 100,
          },
          {
            title: t("claimEnquiry.dealerTradeTotalGrossPrice"),
            dataIndex: "dealerTradeTotalGrossPrice",
            width: 200,
          },
          {
            title: t("claimEnquiry.dealerTradeTotalRetailPrice"),
            dataIndex: "dealerTradeTotalRetailPrice",
            width: 200,
          },
          {
            title: t("claimEnquiry.dealerTradeVatPercent"),
            dataIndex: "dealerTradeVatPercent",
            width: 100,
          },
          {
            title: t("claimEnquiry.operation"),
            fixed: "right",
            width: 120,
            slots: { customRender: "operation" },
          },
        ];
      }
      queryModalYear();
    });
    // 批量查询
    const batchQuery = reactive({
      batchQueryTitle: "",
      batchQueryModal: false,
      batchQueryListTitle: "",
    });
    //Modal的输入框
    const textContentNo = ref<string>("");
    //输入框，用于控制焦点
    const input = ref();
    //显示的VIN数组
    const textNoArray = ref<string[]>([]);
    //空占位
    const batchPlaceholder = ref(["", "", "", "", "", ""]);
    const cleanEmptyEleArray = (array: Array<string>) => {
      if (array.length > 1) {
        const tempArray = array.filter((item) => item !== "");
        tempArray.length || tempArray.push("");
        return tempArray;
      } else {
        return array;
      }
    };
    // 关闭批量查询Modal
    const closeBatchQueryModal = () => {
      queryListParams.vinNo = cleanEmptyEleArray(
        queryListParams.vinNoList
      )[0] as string;
    };
    // 设置批量查询modal 的 占位div
    const setBatchPlaceholder = () => {
      if (textNoArray.value.length) {
        batchPlaceholder.value = [];
      } else {
        batchPlaceholder.value = ["", "", "", "", "", ""];
      }
    };
    // 批量查询按钮事件
    const batchQueryEvent = () => {
      batchQuery.batchQueryModal = true;
      batchQuery.batchQueryTitle = isDealer.value ? "VIN码查询" : "VIN query";
      batchQuery.batchQueryListTitle = isDealer.value
        ? "VIN码列表"
        : "VIN list";
      textNoArray.value =
        cleanEmptyEleArray(queryListParams.vinNoList)[0] === ""
          ? []
          : cleanEmptyEleArray(queryListParams.vinNoList);
      setBatchPlaceholder();
      const dom = document.getElementsByClassName("list-content")[0];
      nextTick(() => {
        dom && dom.scrollTo(0, textNoArray.value.length * 36);
        input.value.focus();
      });
    };
    // 批量文本内容
    const textChangEvent = () => {
      if (textContentNo.value.length < 5) {
        message.error("请从Excel中粘贴复制查询");
        textContentNo.value = "";
        return;
      }
      textNoArray.value = textNoArray.value.concat(
        textContentNo.value.replace(/\r|\n|\s/g, ",").split(",")
      );
      const dom = document.getElementsByClassName("list-content")[0];
      textContentNo.value = "";
      queryListParams.vinNoList = [];
      queryListParams.vinNoList = queryListParams.vinNoList.concat(
        textNoArray.value
      );
      setBatchPlaceholder();
      nextTick(() => {
        dom.scrollTo(0, dom.scrollHeight + 40);
        input.value.focus();
      });
    };

    // 清除批量查询
    const clearBatchQuery = () => {
      queryListParams.vinNoList = [""];
      textNoArray.value =
        cleanEmptyEleArray(queryListParams.vinNoList)[0] === ""
          ? []
          : cleanEmptyEleArray(queryListParams.vinNoList);
      setBatchPlaceholder();
      queryListParams.vinNo = null;
      input.value.focus();
    };

    const checkingResultArray = ref<any>([]);
    const checkingStatusArray = ref<any>([]);
    //获取Check Result，Status的下拉框参数
    const getSelect = () => {
      getFindByGroupCode({ groupCode: "checking_result" }).then((res) => {
        checkingResultArray.value = [];
        checkingResultArray.value = res;
      });
      const type = isDealer.value ? "checkingStatusCN" : "checkingStatus";
      getCheckingState(type).then((res) => {
        const arr = [];
        for (let i = 0; i < res.length; i++) {
          arr.push({
            itemNameEn: isDealer.value ? res[i] : res[i].name,
            itemCode: isDealer.value ? res[i] : res[i].code,
          });
        }
        checkingStatusArray.value = arr;
      });
    };

    const router = useRouter();
    const viewDetail = (record: any) => {
      router.push({
        name: "DealerQueryDetail",
        params: {
          claimId: record.id,
          dealerCode: record.dealerCode,
          // claimId: record.claimId,
        },
      });
    };

    onMounted(() => {
      getSelect();
    });

    return {
      locale,
      placeholder,
      clearBatchQuery,
      textChangEvent,
      closeBatchQueryModal,
      textContentNo,
      input,
      textNoArray,
      batchPlaceholder,
      ...toRefs(batchQuery),
      batchQueryEvent,
      isDealer,
      callBackVisible,
      callBackCode,
      recallUploadDom,
      searchCon,
      tableWidth,
      tableHeight,
      columns,
      checkResultList,
      inParagraphList,
      complaintStatusList,
      queryListParams,
      queryListData,
      params,
      getParams,
      getTableData,
      recallHandle,
      exportResult,
      pagination,
      changePagination,
      changePageSize,
      clearQueryListParams,
      queryTable,
      checkingResultArray,
      checkingStatusArray,
      businessUnitSelect,
      canSelectRegion,
      dogetRegion,
      regionLst,
      viewDetail,
    };
  },
});
